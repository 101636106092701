import React, { Component } from "react";

class EfloraProfile extends Component {
  render() {
    const {
      profiles,
      selectedInput,
      calculateProfileNumber,
      numTotalProfiles,
      editingProfile,
      renderEditProfile,
      fields,
      handleFieldChange,
      handleSave,
      isSaving,
      resetSelectedInput
    } = this.props;
    console.log(this.props)

    const profileInput = selectedInput?.value
      ? selectedInput.value
      : selectedInput;
    return (
      <React.Fragment>
        {profiles.map(profile => (
          <div className="profileWrapper">
            <h2>
              <b>Title</b>: {profile.title}
            </h2>
            <p>
              <b>Body Text</b>: {profile.bodyText}
            </p>
          </div>
        ))}
      </React.Fragment>
    )
  }
}
export default EfloraProfile;

import React, { Component } from "react";

class Profile extends Component {
  render() {
    const {
      profiles,
      selectedInput,
      calculateProfileNumber,
      numTotalProfiles,
      editingProfile,
      renderEditProfile,
      fields,
      handleFieldChange,
      handleSave,
      handleDelete,
      isSaving,
      resetSelectedInput
    } = this.props;

    const profileInput = selectedInput?.value
      ? selectedInput.value
      : selectedInput;
    console.log(profileInput)

    return selectedInput ? (
      <div className="profileWrapper">
        <br />
        <button
          onClick={() => resetSelectedInput()}
          className="editProfileBtn"
        >
          Back To Search
        </button>
        <br />
        <div className="row imageRow">
          <div className="col imageCol">
            <h2>
              <b>{profileInput.name}</b>
            </h2>
            <img
              className={profileInput.image ? "flowImage" : "displayNone"}
              alt={profileInput.name}
              src={profileInput.image}
            />
            {(fields?.image || fields) && (
              <React.Fragment>
                <h2>
                  <b>Image One</b>
                </h2>
                <input
                  name="image"
                  onChange={handleFieldChange}
                  value={fields.image}
                  placeholder={fields.image}
                />
              </React.Fragment>
            )}
          </div>
          <div className="col imageCol">
            <h2>
              <b>{profileInput.name}</b>
            </h2>
            <img
              className={profileInput.imageTwo ? "flowImage" : "displayNone"}
              alt={profileInput.imageTwo}
              src={profileInput.imageTwo}
            />
            {(fields?.imageTwo || fields) && (
              <React.Fragment>
                <h2>
                  <b>Image Two</b>
                </h2>
                <input
                  name="imageTwo"
                  onChange={handleFieldChange}
                  value={fields.imageTwo}
                  placeholder={fields.imageTwo}
                />
              </React.Fragment>
            )}
          </div>
          <div className="col imageCol">
            <h2>
              <b>{profileInput.name}</b>
            </h2>
            <img
              className={profileInput.imageThree ? "flowImage" : "displayNone"}
              alt={profileInput.imageThree}
              src={profileInput.imageThree}
            />
            {(fields?.imageThree || fields) && (
              <React.Fragment>
                <h2>
                  <b>Image Three</b>
                </h2>
                <input
                  name="imageThree"
                  onChange={handleFieldChange}
                  value={fields.imageThree}
                  placeholder={fields.imageThree}
                />
              </React.Fragment>
            )}
          </div>
          <div className="col imageCol">
            <h2>
              <b>{profileInput.name}</b>
            </h2>
            <img
              className={profileInput.imageFour ? "flowImage" : "displayNone"}
              alt={profileInput.imageFour}
              src={profileInput.imageFour}
            />
            {(fields?.imageFour || fields) && (
              <React.Fragment>
                <h2>
                  <b>Image Four</b>
                </h2>
                <input
                  name="imageFour"
                  onChange={handleFieldChange}
                  value={fields.imageFour}
                  placeholder={fields.imageFour}
                />
              </React.Fragment>
            )}
          </div>
        </div>
        <button
          onClick={() => renderEditProfile(true, profileInput)}
          className={editingProfile ? "displayNone" : "editProfileBtn"}
        >
          Edit
        </button>
        <h2>
          <b>Name</b>: {profileInput.name}
        </h2>
        {(fields?.name || fields) && (
          <input
            name="name"
            onChange={handleFieldChange}
            value={fields.name}
            placeholder={profileInput.name}
          />
        )}
        <h3>
          <b>Synonyms</b>: {profileInput && profileInput.synonyms && profileInput.synonyms.map(syn => <span>{syn?.trim()}</span>)}
        </h3>
        {(fields?.synonyms || fields) && (
          <textarea
            onChange={handleFieldChange}
            name="synonyms"
            className="textareaFields"
            rows="4"
            cols="50"
            value={fields.synonyms}
            placeholder={fields.synonyms}
          />
        )}
        <h3>
          <b>Common Name</b>: {profileInput.commonName}
        </h3>
        {(fields?.commonName || fields) && (
          <input
            name="commonName"
            onChange={handleFieldChange}
            value={fields.commonName}
            placeholder={fields.commonName}
          />
        )}
        <h3>
          <b>Type</b>: {profileInput.type}
        </h3>
        {(fields?.type || fields) && (
          <input
            name="type"
            onChange={handleFieldChange}
            value={fields.type}
            placeholder={fields.type}
          />
        )}
        <h3>
          <b>Family</b>: {profileInput.family}
        </h3>
        {(fields?.family || fields) && (
          <input
            name="family"
            onChange={handleFieldChange}
            value={fields.family}
            placeholder={fields.family}
          />
        )}
        <p>
          <b>Native Range</b>: {profileInput.nativeRange}
        </p>
        {(fields?.nativeRange || fields) && (
          <input
            name="nativeRange"
            onChange={handleFieldChange}
            value={fields.nativeRange}
            placeholder={fields.nativeRange}
          />
        )}
        <p>
          <b>Smells Like</b>: {profileInput.smellsLike}
        </p>
        {(fields?.smellsLike || fields) && (
          <input
            name="smellsLike"
            onChange={handleFieldChange}
            value={fields.smellsLike}
            placeholder={fields.smellsLike}
          />
        )}
        <p>
          <b>Chemical Compounds Responsible for the Smell</b>: {profileInput.chemicalCompounds}
        </p>
        {(fields?.chemicalCompounds || fields) && (
          <input
            name="chemicalCompounds"
            onChange={handleFieldChange}
            value={fields.chemicalCompounds}
            placeholder={fields.chemicalCompounds}
          />
        )}
        <p>
          <b>Culture</b>: {profileInput.culture}
        </p>
        {(fields?.culture || fields) && (
          <textarea
            onChange={handleFieldChange}
            name="culture"
            className="textareaFields"
            rows="7"
            cols="50"
            value={fields.culture}
            placeholder={fields.culture}
          />
        )}
        <p>
          <b>Noteworthy Characteristics</b>:{" "}
          {profileInput.noteworthyCharacteristics}
        </p>
        {(fields?.noteworthyCharacteristics || fields) && (
          <textarea
            onChange={handleFieldChange}
            name="noteworthyCharacteristics"
            className="textareaFields"
            rows="7"
            cols="50"
            value={fields.noteworthyCharacteristics}
            placeholder={fields.noteworthyCharacteristics}
          />
        )}
        <p>
          <b>Problems</b>: {profileInput.problems}
        </p>
        {(fields?.problems || fields) && (
          <textarea
            onChange={handleFieldChange}
            name="problems"
            className="textareaFields"
            rows="4"
            cols="50"
            value={fields.problems}
            placeholder={fields.problems}
          />
        )}
        <p>
          <b>Garden Uses</b>: {profileInput.gardenUses}
        </p>
        {(fields?.gardenUses || fields) && (
          <textarea
            onChange={handleFieldChange}
            name="gardenUses"
            className="textareaFields"
            rows="4"
            cols="50"
            value={fields.gardenUses}
            placeholder={fields.gardenUses}
          />
        )}
        <p>
          <b>Interesting Facts</b>: {profileInput.interestingFacts}
        </p>
        {(fields?.interestingFacts || fields) && (
          <textarea
            onChange={handleFieldChange}
            name="interestingFacts"
            className="textareaFields"
            rows="4"
            cols="50"
            value={fields.interestingFacts}
            placeholder={fields.interestingFacts}
          />
        )}
        <p>
          <b>Bibliography</b>: {profileInput && profileInput.biblio && profileInput.biblio.map(bib => <span>{bib?.trim()}</span>)}
        </p>
        {(fields?.biblio || fields) && (
          <textarea
            onChange={handleFieldChange}
            name="biblio"
            className="textareaFields"
            rows="4"
            cols="50"
            value={fields.biblio}
            placeholder={fields.biblio}
          />
        )}
        <br/>
        <button
          onClick={() => handleSave()}
          className={!editingProfile ? "displayNone" : "editProfileBtn"}
        >
          {isSaving ? "...Saving Please Wait" : "Save Changes"}
        </button>
        <br/>
          <button
            onClick={() => handleDelete(profileInput._id)}
            id="deleteBtn"
            className={!editingProfile ? "displayNone" : "editProfileBtn"}
          >
            Delete Profile
          </button>

      </div>
    ) : (
      <React.Fragment>
        {profiles.map((profile, i) => (
          <div className="profileWrapper">
            <br />
          <div className="row imageRow">
            <div className="col imageCol">
              <img
                className={profile.image ? "" : "displayNone"}
                alt={profile.name}
                src={profile.image}
              />
            </div>
            <div className="col imageCol">
              <img
                className={profile.imageTwo ? "" : "displayNone"}
                alt={profile.imageTwo}
                src={profile.imageTwo}
              />
            </div>
            <div className="col imageCol">
              <img
                className={profile.imageThree ? "" : "displayNone"}
                alt={profile.imageThree}
                src={profile.imageThree}
              />
            </div>
            <div className="col imageCol">
              <img
                className={profile.imageFour ? "" : "displayNone"}
                alt={profile.imageFour}
                src={profile.imageFour}
              />
            </div>
          </div>
            <button
              onClick={() => renderEditProfile(true, profile)}
              className="editProfileBtn"
            >
              Edit Profile
            </button>
            <h2>
              <b>Name</b>: {profile.name}
            </h2>
            <h3>
              <b>Synonyms:</b>
            </h3>
              {profile && profile.synonyms && profile.synonyms.map(syn => (
                <p>{syn?.trim()}</p>
              ))}
            <h3>
              <b>Common Name</b>: {profile.commonName}
            </h3>
            <h3>
              <b>Type</b>: {profile.type}
            </h3>
            <h3>
              <b>Family</b>: {profile.family}
            </h3>
            <p>
              <b>Native Range</b>: {profile.nativeRange}
            </p>
            <p>
              <b>Smells Like</b>: {profile.smellsLike}
            </p>
            <p>
              <b>Chemical Compounds Responsible for the Smell</b>: {profile.chemicalCompounds}
            </p>
            <p>
              <b>Culture</b>: {profile.culture}
            </p>
            <p>
              <b>Noteworthy Characteristics</b>:{" "}
              {profile.noteworthyCharacteristics}
            </p>
            <p>
              <b>Problems</b>: {profile.problems}
            </p>
            <p>
              <b>Garden Uses</b>: {profile.gardenUses}
            </p>
            <p>
              <b>Interesting Facts</b>: {profile.interestingFacts}
            </p>
            <p>
              <b>Bibliography</b>
            </p>
              {profile && profile.biblio && profile.biblio.map(biblio => (
                <h6>{biblio?.trim()}</h6>
              ))}
            <br />
            <p id="profileNumber">
              {calculateProfileNumber(i)} of {numTotalProfiles}
            </p>
          </div>
        ))}
      </React.Fragment>
    );
  }
}
export default Profile;

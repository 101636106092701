import React, { Component } from "react";

class WorldFloraProfile extends Component {
  render() {
    const {
      profiles,
      selectedInput,
      calculateProfileNumber,
      numTotalProfiles,
      editingProfile,
      renderEditProfile,
      fields,
      handleFieldChange,
      handleSave,
      isSaving,
      resetSelectedInput
    } = this.props;

    const profileInput = selectedInput?.value
      ? selectedInput.value
      : selectedInput;
    return (
      <React.Fragment>
        {profiles.map(profile => (
          <div className="profileWrapper">
            <h3>
              <b>Bibliography</b>
            </h3>
            <ul>
              {profile && profile.biblio && profile.biblio.map(biblio => (
                <li>
                  {biblio}
                </li>
              ))}
            </ul>
            <h3>
              <b>Synonyms</b>
            </h3>
            <ul>
              {profile && profile.synonyms && profile.synonyms.map( synonym => (
                <li>
                  {synonym}
                </li>
              ))}
            </ul>
            <p>
              <b>General Information</b>: {profile.generalInfo}
            </p>


          </div>
        ))}
      </React.Fragment>
    )
  }
}
export default WorldFloraProfile;

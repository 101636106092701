import React, { Component } from "react";
import Select from "react-select";

class SelectInput extends Component {
  render() {
    const { allProfiles, selectedInput, handleChange } = this.props;

    return (
      <Select
        value={selectedInput}
        isClearable={true}
        onChange={handleChange}
        options={allProfiles}
        placeholder={"Search Profiles...."}
      />
    );
  }
}

export default SelectInput;

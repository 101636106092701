import React, { Component } from "react";

class SingaporeProfile extends Component {
  render() {
    const {
      profiles,
      selectedInput,
      calculateProfileNumber,
      numTotalProfiles,
      editingProfile,
      renderEditProfile,
      fields,
      handleFieldChange,
      handleSave,
      isSaving,
      resetSelectedInput
    } = this.props;

    const profileInput = selectedInput?.value
      ? selectedInput.value
      : selectedInput;
    return (
      <React.Fragment>
      {profiles.map(profile => (
        <div className="profileWrapper">
        <h2>
          <b>Name</b>: {profile.name}
        </h2>
        <h3>
          <b>Common Name</b>: {profile.commonName}
        </h3>
        <h3>
          <b>Type</b>: {profile.type}
        </h3>
        </div>
      ))}
      </React.Fragment>
    )
  }
}
export default SingaporeProfile;

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./actions/index";
import { array } from "prop-types";
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/core";
import "./App.css";

import SelectInput from "./components/SelectInput";
import PlantProfile from "./components/PlantProfile";
import SingaporeProfile from "./components/SingaporeProfile";
import EfloraProfile from "./components/EfloraProfile";
import WorldFloraProfile from "./components/WorldFloraProfile";
import Profile from "./components/Profile";
import CreatingProfile from "./components/CreatingProfile";

class App extends Component {
  state = {
    page: 1,
    selectedInput: null,
    highlightProfile: false,
    editingProfile: false,
    creatingProfile: false,
    fields: null,
    isSaving: false,
    apiEndpoint: "profile",
    type: "profile",
    profile: {}
  };
  static propTypes = {
    profiles: array
  };

  componentDidMount = () => {
    const { page, type, apiEndpoint } = this.state;
    this.props.actions.fetchProfiles(apiEndpoint, page, type);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { page, type, apiEndpoint, selectedInput, fields } = this.state;

    if (prevState.page !== page || prevState.type !== type) {
      this.props.actions.fetchProfiles(apiEndpoint, page, type);
      this.setState({ selectedInput: null });
    }
  };

  calculateProfileNumber = num => {
    const { page } = this.state;
    const profileNumber = num + 1;
    if (page === 1) {
      return page * profileNumber;
    } else {
      return (page - 1) * 25 + profileNumber;
    }
  };

  handleChange = selectedInput => {
    this.setState({ selectedInput });
  };

  renderEditProfile = (editingProfile, profile) => {
    this.setState({ fields: profile, editingProfile, selectedInput: profile });
  };

  renderCreateProfile = creatingProfile => {
    this.setState({ creatingProfile, profile: {} });
    window.scrollTo(0, 0);
  };

  resetSelectedInput = () => {
    this.setState({ selectedInput: null, editingProfile: false, fields: null });
  };

  handleFieldChange = event => {
    const { creatingProfile } = this.state;
    const name = event.target.name;
    const value = event.target.value;

    if (!creatingProfile) {
      this.setState(prevState => ({
        fields: { ...prevState.fields, [name]: value }
      }));
    } else {
      this.setState(prevState => ({
        profile: { ...prevState.profile, [name]: value }
      }));
    }
  };

  handleSave = () => {
    const {
      fields,
      page,
      isSaving,
      type,
      editingProfile,
      creatingProfile,
      profile
    } = this.state;
    if (!creatingProfile && editingProfile) {
      this.props.actions.editProfile(fields, type);
      this.setState({ isSaving: true });
    }
    if (!editingProfile && creatingProfile) {
      this.props.actions.newProfile(profile, type);
      this.setState({ isSaving: true });
    }
  };

  handleDelete = id => {
    const { type } = this.state;
    this.props.actions.deleteProfile(id, type);
    this.setState({ isSaving: true });
  };
  render() {
    const {
      profiles,
      maxPage,
      numTotalProfiles,
      allProfiles,
      isLoading
    } = this.props;
    const {
      page,
      selectedInput,
      editingProfile,
      creatingProfile,
      fields,
      isSaving,
      type,
      apiEndpoint,
      profile
    } = this.state;
    if (isSaving) {
      this.props.actions.fetchProfiles(apiEndpoint, page, type);
      setTimeout(() => {
        this.setState({ isSaving: false }, () => window.location.reload());
      }, 5000);
    }
    const override = css`
      display: block;
      margin: 0 auto;
    `;
    return (
      <div className="App">
        <div className="titleWrapper">
          <h1>SCENTOPEDIA</h1>
          <br />
          <div className="row">
            <button
              onClick={() =>
                this.setState({ type: "profile", apiEndpoint: "profile" })
              }
              className="editProfileBtn"
            >
              All Profiles
            </button>
          </div>
        </div>
        <div
          className={
            selectedInput !== null || creatingProfile || isLoading
              ? "displayNone"
              : "searchWrapper"
          }
        >
          <br />
          <SelectInput
            handleChange={this.handleChange}
            selectedInput={selectedInput}
            allProfiles={allProfiles}
          />
        </div>
        <h2 className={isLoading ? "displayNone" : ""} id="modelType">
          Currently Viewing: <u>{type}</u>
        </h2>
        <br />
        <button
          onClick={() => this.renderCreateProfile(true)}
          id="newProfileBtn"
          className={
            type === "profile" && JSON.stringify(profile) === "{}" && !isLoading
              ? "editProfileBtn"
              : "displayNone"
          }
        >
          Create New Profile
        </button>
        {isLoading && (
          <div className="loading">
            <FadeLoader
              css={override}
              width={5}
              radius={5}
              margin={2}
              height={50}
              color={"#0a497b"}
              loading={true}
            />
            <br />
            <h2>Loading...</h2>
          </div>
        )}
        {creatingProfile &&
          !isLoading && (
            <CreatingProfile
              handleFieldChange={this.handleFieldChange}
              profile={profile}
              creatingProfile={creatingProfile}
              renderCreateProfile={this.renderCreateProfile}
              type={type}
              handleSave={this.handleSave}
            />
          )}

        {!isLoading &&
          !creatingProfile &&
          type &&
          type.includes("profile") && (
            <Profile
              isSaving={isSaving}
              numTotalProfiles={numTotalProfiles}
              selectedInput={selectedInput}
              fields={fields}
              handleSave={this.handleSave}
              handleFieldChange={this.handleFieldChange}
              renderEditProfile={this.renderEditProfile}
              editProfile={this.editProfile}
              editingProfile={editingProfile}
              calculateProfileNumber={this.calculateProfileNumber}
              profiles={profiles}
              resetSelectedInput={this.resetSelectedInput}
              handleDelete={this.handleDelete}
            />
          )}

        <div className={isLoading ? "displayNone" : "paginationWrapper"}>
          <p
            onClick={() => this.setState({ page: page - 1 })}
            className={page > 1 ? "beforeAfterBtn" : "displayNone"}
          >
            Prev
          </p>
          {[...Array(maxPage)].map((_, i) => (
            <p
              onClick={() => this.setState({ page: i + 1 })}
              className={
                i + 1 > page - 4 && i + 1 < page + 4
                  ? "paginateBtn"
                  : "displayNone"
              }
              id={i + 1 === page ? "currentPage" : ""}
            >
              {i + 1}
            </p>
          ))}
          <p
            onClick={() => this.setState({ page: page + 1 })}
            className={page < maxPage ? "beforeAfterBtn" : "displayNone"}
          >
            Next
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

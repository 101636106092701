import axios from "axios";
const SET_PROFILES = "SET_PROFILES";
const SET_ALL_PROFILES = "SET_ALL_PROFILES";
const SET_LOADING = "SET_LOADING";

export const fetchProfiles = (endpoint, page, type) => {
  return dispatch => {
    dispatch({
      type: SET_LOADING,
      loading: true
    });
    axios
      .get(`https://api.scentopedia.net/api/${endpoint}/${page}/${type}`)
      .then(response => {
        dispatch({
          type: SET_PROFILES,
          data: response.data.profiles,
          maxPage: response.data.maxPage,
          numTotalProfiles: response.data.numTotalProfiles,
          allProfiles: response.data.allProfiles
        });
        dispatch({
          type: SET_LOADING,
          loading: false
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: SET_LOADING,
          loading: false
        });
      });
  };
};

export const editProfile = (changes, type) => {
  return dispatch => {
    axios
      .post(`https://api.scentopedia.net/api/edit/${type}`, changes)
      .then(response => {
        console.log(response.status);
      })
      .catch(err => console.log(err));
  };
};

export const newProfile = (profile, type) => {
  return dispatch => {
    axios
      .post(`https://api.scentopedia.net/api/profile/create/${type}`, profile)
      .then(response => {
        console.log(response.status);
      })
      .catch(err => console.log(err));
  };
};

export const deleteProfile = (id, type) => {
  return dispatch => {
    axios
      .post(`https://api.scentopedia.net/api/profile/delete/${type}`, {
        _id: id
      })
      .then(response => {
        console.log(response.status);
      })
      .catch(err => console.log(err));
  };
};

import React, { Component } from "react";

class CreatingProfile extends Component {
  render() {
  const {
    creatingProfile,
    profile,
    handleFieldChange,
    renderCreateProfile,
    type,
    handleSave
  } = this.props;
  //TODO use profile state object to then map over each input
    return (
      <div className="profileWrapper">
        <br/>
        <div className="row imageRow">
          <div className="col imageCol">
            <img
              className={profile.image ? "flowImage" : "displayNone"}
              alt={profile.image}
              src={profile.image}
            />
            <h2>
              <b>Image One</b>
            </h2>
            <input
              name="image"
              onChange={handleFieldChange}
              value={profile.image}
              placeholder={profile.image}
            />
          </div>
          <div className="col imageCol">
            <img
              className={profile.imageTwo ? "flowImage" : "displayNone"}
              alt={profile.imageTwo}
              src={profile.imageTwo}
            />
            <h2>
              <b>Image Two</b>
            </h2>
            <input
              name="imageTwo"
              onChange={handleFieldChange}
              value={profile.imageTwo}
              placeholder={profile.imageTwo}
            />
          </div>
          <div className="col imageCol">
            <img
              className={profile.imageThree ? "flowImage" : "displayNone"}
              alt={profile.imageThree}
              src={profile.imageThree}
            />
            <h2>
              <b>Image Three</b>
            </h2>
            <input
              name="imageThree"
              onChange={handleFieldChange}
              value={profile.imageThree}
              placeholder={profile.imageThree}
            />
          </div>
          <div className="col imageCol">
            <img
              className={profile.imageFour ? "flowImage" : "displayNone"}
              alt={profile.imageFour}
              src={profile.imageFour}
            />
            <h2>
              <b>Image Four</b>
            </h2>
            <input
              name="imageFour"
              onChange={handleFieldChange}
              value={profile.imageFour}
              placeholder={profile.imageFour}
            />
          </div>
        </div>
        <br/>
        <h2>
          <b>Name</b>:
        </h2>
        <input
          name="name"
          onChange={handleFieldChange}
          value={profile.name}
          placeholder={profile.name}
        />
        <h2>
          <b>Synonyms (Comma Separated)</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="synonyms"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.synonyms}
          placeholder={profile.synonyms}
        />
        <h2>
          <b>Common Name</b>:
        </h2>
        <input
          name="commonName"
          onChange={handleFieldChange}
          value={profile.commonName}
          placeholder={profile.commonName}
        />
        <h2>
          <b>Type</b>:
        </h2>
        <input
          name="type"
          onChange={handleFieldChange}
          value={profile.type}
          placeholder={profile.type}
        />
        <h2>
          <b>Family</b>:
        </h2>
        <input
          name="family"
          onChange={handleFieldChange}
          value={profile.family}
          placeholder={profile.family}
        />
        <h2>
          <b>Native Range</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="nativeRange"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.nativeRange}
          placeholder={profile.nativeRange}
        />
        <h2>
          <b>Smells Like</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="smellsLike"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.smellsLike}
          placeholder={profile.smellsLike}
        />
        <h2>
          <b>Chemical Compounds Reponsible for the Smell</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="chemicalCompounds"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.chemicalCompounds}
          placeholder={profile.chemicalCompounds}
        />
        <h2>
          <b>Culture</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="culture"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.culture}
          placeholder={profile.culture}
        />
        <h2>
          <b>Noteworthy Characteristics</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="noteworthyCharacteristics"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.noteworthyCharacteristics}
          placeholder={profile.noteworthyCharacteristics}
        />
        <h2>
          <b>Problems</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="problems"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.problems}
          placeholder={profile.problems}
        />
        <h2>
          <b>Garden Uses</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="gardenUses"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.gardenUses}
          placeholder={profile.gardenUses}
        />
        <h2>
          <b>Interesting Facts</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="interestingFacts"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.interestingFacts}
          placeholder={profile.interestingFacts}
        />
        <h2>
          <b>Bibliography (Comma Separated)</b>:
        </h2>
        <textarea
          onChange={handleFieldChange}
          name="biblio"
          className="textareaFields"
          rows="4"
          cols="50"
          value={profile.biblio}
          placeholder={profile.biblio}
        />
      <button onClick={() => handleSave(profile, type)} className={profile.name ? "editProfileBtn" : "displayNone"}>
          Create Profile
        </button>
        <br/>
        <button onClick={() => renderCreateProfile(false)} className="editProfileBtn">
          Cancel
        </button>

      </div>
    )
  }
}
export default CreatingProfile;

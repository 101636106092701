import { SET_PROFILES, SET_LOADING } from "../actions/index";

const initialState = {
  profiles: [],
  allProfiles: [],
  numTotalProfiles: 0,
  maxPage: 0,
  isLoading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.loading
      };
    case "SET_PROFILES":
      return {
        ...state,
        profiles: action.data,
        maxPage: action.maxPage,
        numTotalProfiles: action.numTotalProfiles,
        allProfiles: action.allProfiles
      };
    default:
      return state;
  }
}
